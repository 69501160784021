@import 'variables';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

* {
  box-sizing: border-box;
}
body {
  &.fbpFormBuilder {
    margin: 0;
    padding: 0;
    background-color: var(--fbp-background-default);
     & .user_signature_content{
      h1,h2,h3,h4,h5,h6,p{
        margin: 0;
        line-height: 22px;
      }
    }
  }
}

.builderElementBasicStyle {
  padding: 8px 16px;
  margin-bottom: 5px;
  box-shadow: 0 5px var(--fbp-action-active-black);
}
.MuiDialogActions-root.MuiDialogActions-spacing,
.MuiPickersToolbar-penIconButtonLandscape,
.MuiPickersToolbar-penIconButton {
  display: none !important;
}

//builder alignment
.fbpHeadingMedium {
  font-size: 28px !important;
  line-height: 38px !important;
  letter-spacing: 0.5px !important;
}
.fbpHeadingSmall {
  font-size: 24px !important;
  line-height: 30px !important;
  letter-spacing: 0.3px !important;
}

.fbpHeadingCenterAlign {
  text-align: center;
}
.fbpHeadingRightAlign {
  text-align: right;
}
.BuilderDropDown {
  width: 100%;
}
.fbpHasLabelAlignment {
  .ComponentLabel {
    position: relative;
  }
  .labelAlignLeft,
  .labelAlignRight {
    display: flex;
    align-items: center;
    gap: 10px;
    .BuilderDropDown {
      width: calc(100% - 177px);
    }
  }
  .labelAlignLeft {
    .ComponentLabel {
      flex-shrink: 0;
      flex-basis: 177px;
      margin-bottom: 0;
    }
  }
  .labelAlignRight {
    .ComponentLabel {
      flex-shrink: 0;
      flex-basis: 177px;
      margin-bottom: 0;
      justify-content: flex-end;
      text-align: right;
    }
  }
}

.fbpComponentGridView {
  .radioItemLabel {
    width: 192px;
    height: 192px;
    border: $fbp-outlined-border2;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px;
    position: relative;
    border-radius: var(--fbp-border-radius);
    flex: 1 0 calc(25% - 16px);
    background-color: var(--fbp-theming-input-background);
    &:hover {
      border-color: $fbp-secondary-main;
    }
    &:focus {
      border-color: $fbp-secondary-main;
    }
    &::after {
      content: url('https://d24gujj67p1uc1.cloudfront.net/asset/images/forms/releases/1.0.0/CheckCircleOutline.png');
      width: 36px;
      height: 36px;
      border-radius: 0 4px 0 8px;
      background: var(--fbp-primary-main);
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: all 0.3s;
    }
    &.fbpRadioSelected {
      &::after {
        opacity: 1;
      }
    }
  }
}

.MuiIconButton-root:focus {
  background-color: unset;
}
.fbpTabView {
  .fbp_single_select_radio_box {
    flex-basis: 33.333333%;
    max-width: 50%;
  }
}
.fbpPhoneView {
  .fbpHasLabelAlignment {
    .labelAlignLeft,
    .labelAlignRight {
      display: block;
      .ComponentLabel {
        justify-content: start;
      }
    }
  }
  //card view progressbar
  .cardViewProgressBar {
    display: none;
  }
  //single select
  .fbp_single_select_radio_box {
    flex-basis: 50%;
    max-width: 50%;
    min-width: 50%;
  }
  .form_header_wrapper {
    padding: var(--fbp-theming-field-spacing) 15px;
    .fh-logo-image-box {
      max-width: 90px;
    }
  }
  .form_footer_wrapper {
    padding: var(--fbp-theming-field-spacing) 15px;
  }
}

@media (max-width: 600px) {
  .fbpHasLabelAlignment {
    .labelAlignLeft,
    .labelAlignRight {
      display: block;
      .ComponentLabel {
        justify-content: start;
      }
    }
  }
}

// TODO should be removed later
//chip style
