ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.fbp_has_scroll {
  height: 100%;
  overflow-y: auto;
}
.fbp_has_scroll::-webkit-scrollbar-track {
  box-shasdow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #112c4f;
  opacity: 1;
}
.fbp_has_scroll::-webkit-scrollbar {
  width: 6px;
}
.fbp_has_scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #5a5bd7;
}

.fbp_has_xscroll {
  height: 100%;
  overflow-x: auto;
}
.fbp_has_xscroll::-webkit-scrollbar-track {
  -webkit-box-shasdow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: var(--dark_blue);
}

.fbp_has_xscroll-80::-webkit-scrollbar-track {
  margin-top: 30px;
}
.fbp_has_xscroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.fbp_has_xscroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.fbp_has_xscroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--purple);
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.global-inherit-aTag{
  text-decoration: underline;
  color: inherit;
}